/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import socialImage from "../images/bwp-social-share.jpg";
import siteLogo from "../images/ben-wulf-photography-logo-brand.jpg";

function SEO({ description, lang = "en", meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const siteURL = site.siteMetadata.siteUrl;

  //some structured content test
  const structuredContact = {
    "@context": "https://schema.org",
    "@type": "Organization",
    url: "http://benwulfphotography.co.uk",
    logo: `${siteURL}${siteLogo}`,
    name: "Ben Wulf Photography",
    contactPoint: {
      "@type": "ContactPoint",
      email: "info@benwulfphotography.co.uk",
      contactType: "Support",
    },
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: `ben wulf photography, headshots london, actors headshots, actor headshots, headshot, headshots, headshot photographer, headshot photography, headshot photo, london professional headshots, headshot photographer london, studio portrait photography, headshots for actors`,
        },
        {
          property: `og:title`,
          content: `${title} | ${defaultTitle}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: `${siteURL}${socialImage}`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: siteURL,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:site`,
          content: `@benwulfphotography`,
        },
        {
          name: `twitter:image`,
          content: `${siteURL}${socialImage}`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: `${title} | ${defaultTitle}`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify(structuredContact)}
      </script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
