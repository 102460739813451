/** @jsxImportSource theme-ui */
import React, { useState } from "react";
import { Link } from "gatsby";
import siteLogo from "../images/ben-wulf-logo.svg";

const Header = (props) => {
  const [navOpen, setNavOpen] = useState(false);

  const toggleNavBar = () => {
    console.log("burger clicked");
    const navBarBurger = document.querySelector(".navbar-burger");
    const navBarMenu = document.querySelector(".navbar-menu");
    if (navOpen) {
      navBarMenu.classList.remove("is-active");
      navBarBurger.classList.remove("is-active");
      setNavOpen(false);
    } else {
      navBarMenu.classList.add("is-active");
      navBarBurger.classList.add("is-active");
      setNavOpen(true);
    }
  };

  return (
    <header
      sx={{
        display: "block",
        "@media screen and (min-width: 1024px)": {
          display: "flex",
          justifyContent: "space-between",
        },
      }}
    >
      <div
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Link to="/">
          <img
            src={siteLogo}
            alt="Ben Wulf Photography Logo"
            sx={{ height: ["100px", "140px"] }}
            height="140px"
          />
        </Link>
        <a
          role="button"
          className="navbar-burger burger"
          aria-label="menu toggle"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={toggleNavBar}
          sx={{
            display: "flex",
            alignSelf: "center",
            color: "muted",
            height: "3.25rem",
            position: "relative",
            width: "3.25rem",
            marginLeft: "auto",
            span: {
              height: "1.5px",
              left: "calc(50% - 12px)",
              width: "24px",
              background: "muted",
              display: "block",
              position: "absolute",
              transformOrigin: "center",
              transformDuration: "86ms",
              transitionProperty:
                "background-color,opacity,transform,-webkit-transform",
              transitionTimingFunction: "ease-out",
              "&:first-child": {
                top: "calc(50% - 8px)",
              },
              "&:nth-child(2)": {
                top: "calc(50% - 2px)",
              },
              "&:nth-child(3)": {
                top: "calc(50% + 4px)",
              },
            },
            "&:hover, &:focus-within": {
              background: "rbga(0,0,0,0.05)",
            },
            "@media screen and (min-width: 1024px)": {
              display: "none",
            },
          }}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <nav
        aria-label="Main Menu"
        className="navbar-menu"
        sx={{
          display: "none",

          opacity: 0,
          transition: "all 0.7s ease",
          "@media screen and (min-width: 1024px)": {
            opacity: 1,
            display: "flex",
          },
          ul: {
            display: "flex",
            alignItems: "center",
            height: "140px", //same as logo
            margin: 0,

            li: {
              listStyle: "none",
              px: 3,
              height: "100%",
              display: "flex",
              alignItems: "center",
              "&:hover, &:focus-within": {
                bg: "backgroundMuted",
              },
              a: {
                textDecoration: "none",
                color: "rgba(0,0,0,0.5)",
                fontSize: "1.2rem",
                display: "block",
                py: 2,
                "&:hover, &:focus-within": {
                  color: "rgba(0,0,0,0.75)",
                },
                "&.is-active": {
                  color: "rgba(0,0,0,1)",
                },
              },
            },
          },
          //is-active open menu in mobile view
          "@media screen and (max-width: 1023px)": {
            "&.is-active": {
              display: "block",
              opacity: 1,
              //height: "auto",
              ul: {
                display: "block",
                height: "auto",
                pl: 0,
                li: {
                  "& a.is-active": {
                    pl: 2,
                  },
                },
              },
            },
          },
        }}
      >
        <ul>
          <li
            sx={{
              display: "flex",
              "@media screen and (min-width: 1024px)": {
                display: "none !important",
              },
            }}
          >
            <Link to="/" activeClassName="is-active">
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/portfolio"
              activeClassName="is-active"
              partiallyActive={true}
            >
              Portfolio
            </Link>
          </li>
          <li>
            <Link
              to="/corporate"
              activeClassName="is-active"
              partiallyActive={true}
            >
              Corporate
            </Link>
          </li>
          <li>
            <Link
              to="/dancers"
              activeClassName="is-active"
              partiallyActive={true}
            >
              Dancers
            </Link>
          </li>
          {/*
          <li>
            <Link
              to="/project/people-of-the-pound"
              activeClassName="is-active"
              partiallyActive={true}
            >
              Projects
            </Link>
          </li>
          */}
          <li>
            <Link
              to="/about"
              activeClassName="is-active"
              partiallyActive={true}
            >
              About
            </Link>
          </li>
          <li>
            <Link to="/faqs" activeClassName="is-active" partiallyActive={true}>
              FAQs
            </Link>
          </li>
          <li>
            <Link to="/blog" activeClassName="is-active" partiallyActive={true}>
              Blog
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              activeClassName="is-active"
              partiallyActive={true}
            >
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
