/** @jsxImportSource theme-ui */
import * as React from "react";
import Layout from "../../components/Layout";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";
import SEO from "../../components/Seo";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { SliceZone } from "../../components/SliceZone";

const BlogPostPage = ({ data }) => {
  console.log("data", data);
  const postData = data.prismicBlogPosts.data;
  const sliceData = data.prismicBlogPosts.data.body;
  const postImage = getImage(postData.image);
  return (
    <Layout>
      <SEO title={`${postData.title.text}`} />

      <div sx={{ fontSize: 4, px: [3, 2, 1] }}>
        {/* {postImage && (
          <div sx={{ border: "1px solid black" }}>
            <GatsbyImage image={postImage} alt={postData.image.alt} />
          </div>
        )} */}

        <h1>{postData.title.text}</h1>
        <h3 sx={{ color: "muted" }}>{postData.fullDate}</h3>
        <div
          sx={{
            color: "muted",
            lineHeight: 1.6,
            "& p.block-img": {
              maxHeight: "300px",
              display: "inline-block",
              px: 2,
              ml: ["auto", "3%"],
              width: ["auto", "45%", "30%"],
              img: {
                width: "100%",
                border: "1px solid black",
              },
            },
          }}
        >
          <PrismicRichText field={postData.text.raw} />
        </div>
        <div sx={{ pb: 3 }}>
          <SliceZone sliceZone={sliceData} />
        </div>

        <div
          sx={{
            hr: {
              color: "muted",
              mt: 4,
              mb: 0,
            },
          }}
        >
          <hr />

          <div
            sx={{
              mb: 6,
              mt: 3,
              display: "flex",
              justifyContent: "space-between",
              "& a.button": {
                display: "inline-block",
                fontSize: 2,
                lineHeight: 1,
                textDecoration: "none",
                borderRadius: "3px",
                bg: "muted",
                color: "white",
                px: 3,
                py: 2,
                transition: "all 0.3s ease",
                "&:hover": {
                  bg: "black",
                  color: "white",
                },
              },
              "& a.backlink": {
                display: "inline-block",
                fontSize: 2,
                lineHeight: 1,
                px: 3,
                py: 2,
              },
            }}
          >
            <Link to="/blog" className="button">
              Back to List
            </Link>
            {postData.backlink.url && (
              <a className="backlink" href={postData.backlink.url}>
                {postData.backlink_text}
              </a>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withPrismicPreview(BlogPostPage);

export const pageQuery = graphql`
  query BlogPostPageQuery($uid: String!) {
    prismicBlogPosts(uid: { eq: $uid }) {
      _previewable
      data {
        shortDate: date(formatString: "DD MMM YY")
        fullDate: date(formatString: "DD MMMM, YYYY")
        image {
          alt
          gatsbyImageData(aspectRatio: 1.77)
        }
        text {
          richText
          text
          raw
        }
        title {
          raw
          richText
          text
        }
        backlink {
          url
        }
        backlink_text
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...BlogPostsDataBodyImagerow
          ...BlogPostsDataBodyTextrow
          ...BlogPostsDataBodySideimagerow
        }
      }
    }
  }
`;
