// SliceZone.js file

import * as React from "react";

import { ImageRow } from "../slices/ImageRow";
import { TextRow } from "../slices/TextRow";
import { SideImageRow } from "../slices/SideImageRow";
import { SideTextRow } from "../slices/SideTextRow";
import { ContactRow } from "../slices/ContactRow";
import { FaqRow } from "../slices/FaqRow";

export const SliceZone = ({ sliceZone }) => {
  console.log("sliceZone", sliceZone);

  const sliceComponents = {
    imagerow: ImageRow,
    textrow: TextRow,
    sideimagerow: SideImageRow,
    sidetextrow: SideTextRow,
    contactrow: ContactRow,
    faqrow: FaqRow,
  };

  const sliceZoneContent = sliceZone.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type];
    if (SliceComponent) {
      return <SliceComponent slice={slice} key={`slice-${index}`} />;
    }
    return null;
  });

  return <section className="slice-container">{sliceZoneContent}</section>;
};
