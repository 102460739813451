import "@fontsource/roboto";

import * as React from "react";
import { Link } from "gatsby";
import { PrismicProvider } from "@prismicio/react";
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";
import { linkResolver } from "./src/utils/LinkResolver";

import Index from "./src/pages/index";
import About from "./src/pages/about";
import Contact from "./src/pages/contact";
import BlogPage from "./src/pages/blog/{prismicBlogPosts.uid}";

//object key below come from api id in prismic and object values are react components above

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    linkResolver={linkResolver}
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          index: Index,
          about: About,
          contact: Contact,
          blog_posts: BlogPage,
        }),
      },
    ]}
  >
    <PrismicProvider
      linkResolver={linkResolver}
      internalLinkComponent={({ href, ...props }) => (
        <Link to={href} {...props} />
      )}
    >
      {element}
    </PrismicProvider>
  </PrismicPreviewProvider>
);
