/** @jsxImportSource theme-ui */
import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";
import SignupForm from "../components/ContactFormHooks";
import { ContactFormOtherMethods } from "../components/ContactOtherMethods";

export const ContactRow = ({ slice }) => {
  return (
    <section
      className={`content-section contactRow`}
      sx={{
        display: ["block", "flex"],

        my: 6,
        "&.left": {
          flexDirection: "row-reverse",
          "& .sideText": {
            pl: [0, 3],
            pr: 0,
          },
        },

        "& .contactText": {
          width: ["auto", "45%"],
          mr: ["auto", "5%"],
          fontSize: 4,
          p: { color: "muted" },
          h2: { color: "headings" },
          strong: { color: "black", fontWeight: 700 },
        },
        "& .contactForm": {
          width: ["auto", "45%"],
          ml: ["auto", "5%"],
        },
      }}
    >
      <div className="contactText" sx={{ color: "muted", lineHeight: 1.6 }}>
        <h2>{slice.primary.title1.text}</h2>
        <PrismicRichText field={slice.primary.text.richText} />
      </div>
      <div className="contactForm">
        <SignupForm />
        <ContactFormOtherMethods />
      </div>
    </section>
  );
};

export const query = graphql`
  fragment AHLContactrow on PrismicActorsHeadshotsLondonDataBodyContactrow {
    id
    slice_type
    primary {
      contactform
      title1 {
        richText
        text
      }
      text {
        richText
        text
      }
    }
  }
  fragment HomeContactrow on PrismicHomePageDataBodyContactrow {
    id
    slice_type
    primary {
      contactform
      title1 {
        richText
        text
      }
      text {
        richText
        text
      }
    }
  }
`;

// fragment BlogPostsDataBodySidetextrow on PrismicBlogPostsDataBodySidetextrow {
//   id
//   slice_type
//   primary {
//     alignment
//     title1 {
//       richText
//       text
//     }
//     text {
//       richText
//     }
//   }
// }
