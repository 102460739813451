/** @jsxImportSource theme-ui */
import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import {
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaGlobe,
} from "react-icons/fa";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";

const Socials = () => {
  const data = useStaticQuery(graphql`
    query socialsQuery {
      prismicSitewide {
        data {
          social_media {
            link {
              url
              slug
              link_type
              id
            }
            logo
            text {
              raw
              html
              richText
              text
            }
          }
        }
      }
    }
  `);

  return (
    <div
      sx={{
        a: {
          color: "muted",
          p: 2,

          fontSize: "2rem",
          transition: "all 0.3s ease",

          "@media (hover: hover) and (pointer: fine)": {
            "&:hover, &:focus-within": { color: "black" },
            p: 1,
            fontSize: "1.25rem",
          },
        },
      }}
    >
      {data.prismicSitewide.data.social_media.map((item, index) => {
        let icon;
        if (item.logo === "Twitter") {
          icon = (
            <>
              <FaTwitter />
              <span className="visuallyHidden">Twitter</span>
            </>
          );
        } else if (item.logo === "Facebook") {
          icon = (
            <>
              <FaFacebook />
              <span className="visuallyHidden">Twitter</span>
            </>
          );
        } else if (item.logo === "Instagram") {
          icon = (
            <>
              <FaInstagram />
              <span className="visuallyHidden">Twitter</span>
            </>
          );
        } else if (item.logo === "LinkedIn") {
          icon = (
            <>
              <FaLinkedin />
              <span className="visuallyHidden">Twitter</span>
            </>
          );
        } else if (item.logo === "Web Symbol") {
          icon = (
            <>
              <FaGlobe />
              <span className="visuallyHidden">Twitter</span>
            </>
          );
        } else {
          icon = item.text.text;
        }
        return (
          <a href={item.link.url} key={`social${index}`}>
            {icon}
          </a>
        );
      })}
    </div>
  );
};

export default Socials;
