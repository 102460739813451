/** @jsxImportSource theme-ui */
import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const ImageRow = ({ slice }) => (
  <section
    className="content-section imageRow"
    sx={{
      display: "flex",
      flexDirection: ["column", "row", "row"],
      justifyContent: "space-around",
      flexWrap: "wrap",
      //my: 6,
      figure: {
        margin: ["1em auto", "1em 0.5em"],
        figcaption: {
          mx: 2,
        },
      },
      "& .gatsby-image-wrapper": {
        height: ["auto"], //350
        width: "auto",
        // width: ["auto"],
        mx: [1, 2],
        my: [2],
      },
      "& img": {
        //objectFit: "contain !important",
        border: "1px solid black",
        width: "auto",
        height: "100%",
      },
    }}
  >
    {slice.items.map((item, index) => {
      const image = getImage(item.image1);
      let caption = true;
      if (item.name.text === "") {
        caption = false;
      }
      return (
        <figure>
          <GatsbyImage
            key={`imageRow${index}`}
            alt={item.image1.alt}
            image={image}
          />
          {caption && <figcaption>{item.name.text}</figcaption>}
        </figure>
      );
    })}
  </section>
);

export const query = graphql`
  fragment BlogPostsDataBodyImagerow on PrismicBlogPostsDataBodyImagerow {
    id
    slice_type
    items {
      image1 {
        alt
        gatsbyImageData(height: 500)
      }
      name {
        text
        richText
      }
    }
  }

  fragment AHLImagerow on PrismicActorsHeadshotsLondonDataBodyImagerow {
    id
    slice_type
    items {
      image1 {
        alt
        gatsbyImageData(height: 500)
      }
      name {
        text
        richText
      }
    }
  }

  fragment HomeImagerow on PrismicHomePageDataBodyImagerow {
    id
    slice_type
    items {
      image1 {
        alt
        gatsbyImageData(height: 500)
      }
      name {
        text
        richText
      }
    }
  }
`;
