/** @jsxImportSource theme-ui */
import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children }) => {
  return (
    <div
      className="siteContainer"
      sx={{
        //link styles
        a: {
          color: "muted",
          "&:hover, &:focus-within": {
            textDecoration: "none",
            color: "black",
          },
        },
        "& .visuallyHidden": {
          //josh comeau
          display: "inline-block",
          position: "absolute",
          overflow: "hidden",
          clip: "rect(0 0 0 0)",
          height: 1,
          width: 1,
          margin: -1,
          padding: 0,
          border: 0,
        },
        //font legibitity and smoothing
        textRendering: "optimizeLegibility",
        WebkitFontSmoothing: "antialiased",
        // "@media (min-width: 81.25em)": {
        //   maxWidth: "81.25rem",
        //   mx: "auto",
        // },
        mx: "auto",
        padding: 3,
        "@media (min-width: 576px)": {
          maxWidth: "540px",
        },
        "@media (min-width: 768px)": {
          maxWidth: "720px",
        },
        "@media (min-width: 992px)": {
          maxWidth: "960px",
        },
        "@media (min-width: 1200px)": {
          maxWidth: "1140px",
        },
      }}
    >
      <div
        className="siteContent"
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          "header,footer": {
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: "auto",
          },
          main: {
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: "auto",
          },
        }}
      >
        <Header />
        <main className="pageContent">{children}</main>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
