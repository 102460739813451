import * as React from "react";
import { Link } from "gatsby";
import Socials from "../components/SocialMediaIcons";
import Obfuscate from "react-obfuscate";

export const ContactFormOtherMethods = () => {
  return (
    <div>
      <p>
        Get in touch with me via the form above, email me direct on
        {"  "}
        <Obfuscate email="info@benwulfphotography.co.uk" />, or reach out on my
        social media below.
      </p>
      <Socials />
    </div>
  );
};
