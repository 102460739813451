/** @jsxImportSource theme-ui */
import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";

export const SideTextRow = ({ slice }) => {
  return (
    <section
      className={`content-section sideTextRow ${slice.primary.alignment} `}
      sx={{
        display: ["flex"],
        flexWrap: "wrap",
        justifyContent: ["space-between"],
        my: 6,
        "&.left": {
          flexDirection: "row-reverse",
          "& .sideText": {
            pl: [0, 3],
            pr: 0,
          },
        },

        "& .sideText": {
          maxWidth: ["100%", "60%", "65%"],
          pr: [0, 3],
          pl: 0,
          fontSize: 3,
          lineHeight: 1.6,
          "p:first-child": {
            mt: 0,
          },
        },
      }}
    >
      <div className="sideText" sx={{ color: "muted", lineHeight: 1.6 }}>
        <PrismicRichText field={slice.primary.text.richText} />
      </div>
      <div className="sideHeading">
        <h2>{slice.primary.title1.text}</h2>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment AHLSidetextrow on PrismicActorsHeadshotsLondonDataBodySidetextrow {
    id
    slice_type
    primary {
      alignment
      title1 {
        richText
        text
      }
      text {
        richText
      }
    }
  }

  fragment HomeSidetextrow on PrismicHomePageDataBodySidetextrow {
    id
    slice_type
    primary {
      alignment
      title1 {
        richText
        text
      }
      text {
        richText
      }
    }
  }
`;

// fragment BlogPostsDataBodySidetextrow on PrismicBlogPostsDataBodySidetextrow {
//   id
//   slice_type
//   primary {
//     alignment
//     title1 {
//       richText
//       text
//     }
//     text {
//       richText
//     }
//   }
// }
