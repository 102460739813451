/** @jsxImportSource theme-ui */
import * as React from "react";
import { graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";

export const TextRow = ({ slice }) => {
  //console.log(slice);
  return (
    <section
      className="content-section textRow"
      sx={{
        color: "muted",
        lineHeight: 1.6,
        fontSize: 3,
        my: 6,
        h2: {
          fontSize: 5,
          color: "black",
        },
        "& p.block-img": {
          maxHeight: "300px",
          display: "inline-block",
          px: 2,
          ml: ["auto", "3%"],
          width: ["auto", "45%", "30%"],
          img: {
            width: "100%",
            border: "1px solid black",
          },
        },
      }}
    >
      <PrismicRichText field={slice.primary.text1.richText} />
    </section>
  );
};

export const query = graphql`
  fragment BlogPostsDataBodyTextrow on PrismicBlogPostsDataBodyTextrow {
    id
    slice_type
    primary {
      text1 {
        richText
        text
        raw
      }
    }
  }

  fragment AHLTextrow on PrismicActorsHeadshotsLondonDataBodyTextrow {
    id
    slice_type
    primary {
      text1 {
        richText
        text
        raw
      }
    }
  }

  fragment HomeTextrow on PrismicHomePageDataBodyTextrow {
    id
    slice_type
    primary {
      text1 {
        richText
        text
        raw
      }
    }
  }
`;
