/** @jsxImportSource theme-ui */
import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import { PrismicRichText } from "@prismicio/react";
//import ContactForm from "../components/ContactForm";
import SignupForm from "../components/ContactFormHooks";
//import ContactForm from "../components/ContactFormium";
import Socials from "../components/SocialMediaIcons";
import Obfuscate from "react-obfuscate";
import SEO from "../components/Seo";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { ContactFormOtherMethods } from "../components/ContactOtherMethods";

// markup
const ContactPage = ({ data }) => {
  const pageData = data.prismicContactPage.data;

  return (
    <Layout>
      <SEO
        title="Contact"
        description="Contact Ben Wulf Photography, London headshot and portrait photographer based in Greenwich in South East London."
      />
      <div sx={{ px: [2, 1, 0] }}>
        <h1>{pageData.title.text}</h1>
        <div
          sx={{
            display: ["block", "flex"],
            my: 5,

            "& .contactText": {
              width: ["auto", "45%"],
              mr: ["auto", "5%"],
              fontSize: 4,
              p: { color: "muted" },
              h1: { color: "headings" },
              strong: { color: "black", fontWeight: 700 },
            },
            "& .contactForm": {
              width: ["auto", "45%"],
              ml: ["auto", "5%"],
            },
          }}
        >
          <div className="contactText">
            <PrismicRichText field={pageData.text.raw} />
          </div>
          <div className="contactForm">
            <SignupForm />
            <div
              className="contactOther"
              sx={{
                fontSize: 4,
                p: { color: "muted" },
                svg: {
                  fontSize: "1.25em",
                },
              }}
            >
              <ContactFormOtherMethods />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withPrismicPreview(ContactPage);

export const query = graphql`
  query contactPageQuery {
    prismicContactPage {
      _previewable
      data {
        text {
          raw
          richText
          text
        }
        title {
          raw
          richText
          text
        }
      }
      last_publication_date
    }
  }
`;
