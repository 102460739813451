/** @jsxImportSource theme-ui */
import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";

export const SideImageRow = ({ slice }) => {
  const image = getImage(slice.primary.side_image);
  const imageLink = slice.primary.image_link?.url;
  return (
    <section
      className={`content-section sideImageRow ${slice.primary.image_position} `}
      sx={{
        display: ["flex"],
        flexWrap: "wrap",
        justifyContent: ["space-between"],
        my: 4,
        "&.Left": {
          flexDirection: "row-reverse",
          "& .sideText": {
            pl: [0, 3],
            pr: 0,
          },
          "& .sideImage": {
            display: "flex",
            justifyContent: ["center", "left", "left"],
          },
        },
        "& .sideImage": {
          flex: 1,
          width: ["100%", "50%", "50%"],
          display: "flex",
          justifyContent: ["center", "right", "right"],
          "& .gatsby-image-wrapper": {
            // height: "350px",
            width: "auto",

            px: 2,
            "& img": {
              //border: "1px solid black",
              //width: "auto",
              height: "100%",
              objectFit: "scale-down",
            },
          },
        },
        "& .sideText": {
          fontSize: 3,
          flex: "1 100 auto",
          maxWidth: ["100%", "50%", "50%"],
          alignSelf: "center",
          pr: [0, 3],
          pl: 0,
          "p:first-child": {
            mt: 0,
          },
        },
      }}
    >
      <div className="sideText" sx={{ color: "muted", lineHeight: 1.6 }}>
        <PrismicRichText field={slice.primary.side_text.richText} />
      </div>
      <div className="sideImage">
        {imageLink ? (
          <a href={imageLink}>
            <GatsbyImage
              alt={slice.primary.side_image.alt}
              image={image}
              //for ben affinity image (is it useful generally)
              objectFit="scale-down"
            />
          </a>
        ) : (
          <GatsbyImage
            alt={slice.primary.side_image.alt}
            image={image}
            objectFit="scale-down"
          />
        )}
      </div>
    </section>
  );
};

export const query = graphql`
  fragment BlogPostsDataBodySideimagerow on PrismicBlogPostsDataBodySideimagerow {
    id
    slice_type
    primary {
      image_position
      image_link {
        link_type
        slug
        url
      }
      side_image {
        alt
        gatsbyImageData(height: 350)
      }
      side_text {
        richText
      }
    }
  }

  fragment AHLSideimagerow on PrismicActorsHeadshotsLondonDataBodySideimagerow {
    id
    slice_type
    primary {
      image_position
      image_link {
        link_type
        slug
        url
      }
      side_image {
        alt
        gatsbyImageData(height: 550)
      }
      side_text {
        richText
      }
    }
  }

  fragment HomeSideimagerow on PrismicHomePageDataBodySideimagerow {
    id
    slice_type
    primary {
      image_position
      image_link {
        link_type
        slug
        url
      }
      side_image {
        alt
        gatsbyImageData(height: 550)
      }
      side_text {
        richText
      }
    }
  }
`;
