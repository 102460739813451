/** @jsxImportSource theme-ui */
import * as React from "react";
import Layout from "../components/Layout";
import Carousel from "../components/Carousel";
import { graphql, Link } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import SEO from "../components/Seo";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { SliceZone } from "../components/SliceZone";
// markup
const IndexPage = ({ data }) => {
  const pageData = data.prismicHomePage.data;
  const sliceData = data.prismicHomePage.data.body;
  return (
    <Layout>
      <SEO
        title="Actors Headshots and Portraits London"
        description="London headshot and portrait photographer based in Greenwich in South East London. Professional headshots for actors."
      />

      <div
        sx={{
          h2: {
            display: ["none", "block"],
            fontSize: 6,
            color: "headings",
            fontWeight: 500,
            mt: "20px",
            mb: 4,
          },
          h1: {
            display: ["none", "block"],
            fontSize: 6,
            color: "headings",
            fontWeight: 500,
            mt: "20px",
            mb: 4,
          },
        }}
      >
        <PrismicRichText field={pageData.tagline.raw} />
      </div>

      <Carousel images={pageData.carousel} />
      <div
        className="afterTheFold"
        sx={{
          mt: 7,
          mb: 6,
          fontSize: [3, 4],
          p: { color: "muted" },
          h2: { color: "headings", fontSize: [4, 5] },
        }}
      >
        <PrismicRichText field={pageData.title.raw} />
        <PrismicRichText field={pageData.text.raw} />
      </div>
      <div sx={{ pb: 3 }}>
        <SliceZone sliceZone={sliceData} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query homepageQuery {
    prismicHomePage {
      _previewable
      data {
        carousel {
          image {
            gatsbyImageData
            alt
          }
          link {
            url
            slug
            link_type
          }
          name {
            html
            raw
          }
        }
        tagline {
          raw
          html
        }
        title {
          raw
          html
        }
        text {
          raw
          html
        }
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...HomeImagerow
          ...HomeSideimagerow
          ...HomeTextrow
          ...HomeSidetextrow
          ...HomeContactrow
          ...HomeFaqrow
        }
      }

      last_publication_date
    }
  }
`;

export default withPrismicPreview(IndexPage);
