/** @jsxImportSource theme-ui */
import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";
import SEO from "../components/Seo";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// markup
const AboutPage = ({ data }) => {
  const pageData = data.prismicAboutPage.data;
  //let dateTest = new Date("2022-01-31T17:23:32+0000");
  //console.log("date", dateTest);
  return (
    <Layout>
      <SEO
        title="About"
        description="Portrait Photographer for Actors Headshots based in Greenwich in South East London. Professional studio photography."
      />
      <div>
        <h1>{pageData.title.text}</h1>
        <div
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",

            "& .gatsby-image-wrapper": {
              // flexBasis: ["50%", "auto"],
              // flexGrow: 0,
              // flexShrink: 0,
              width: ["48%", "30%"],
              margin: ["1%", "1.5%"],
              "& img": {
                border: "1px solid black",
              },
            },
          }}
        >
          {pageData.gallery.map((item, index) => {
            const image = getImage(item.image);
            return (
              <GatsbyImage
                image={image}
                alt={item.image.alt}
                key={`about${index}`}
                sx={{}}
              />
            );
          })}
        </div>
        <div
          sx={{
            mt: 4,
            mb: 4,
            fontSize: 4,
            px: [2, 1, 0],
            p: { color: "muted" },
            h1: { color: "headings" },
          }}
        >
          <PrismicRichText field={pageData.text.richText} />
        </div>
      </div>
    </Layout>
  );
};

export default withPrismicPreview(AboutPage);

export const query = graphql`
  query aboutPageQuery {
    prismicAboutPage {
      _previewable
      data {
        gallery {
          image {
            alt
            gatsbyImageData
          }
        }
        text {
          richText
          text
          raw
        }
        title {
          text
          richText
        }
      }
      last_publication_date
    }
  }
`;
