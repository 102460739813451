/** @jsxImportSource theme-ui */
import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useForm } from "@formcarry/react";
import axios from "axios";
import { Button, Message } from "theme-ui";

const SignupForm = () => {
  //const { state, submit } = useForm({ id: "Xo9vRdMOevW" });

  const [formSuccess, setFormSuccess] = useState(false);
  const [formError, setFormError] = useState(false);

  return (
    <>
      <Formik
        initialValues={{ name: "", email: "", message: "" }}
        validationSchema={Yup.object({
          name: Yup.string()
            .max(15, "Must be 15 characters or less")
            .required("Please add your name"),
          email: Yup.string()
            .email("Invalid email address")
            .required("Required"),
          message: Yup.string().required("Required"),
        })}
        onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
          // setTimeout(() => {
          //   // alert(JSON.stringify(values, null, 2));

          //   setSubmitting(false);
          // }, 400);
          axios
            .post("https://formcarry.com/s/Xo9vRdMOevW", values)
            .then((response) => {
              console.log(response);
              setFormSuccess(true);
              resetForm();
            })
            .catch(function (error) {
              console.log(error);
              setFormError(true);
            });
        }}
      >
        <Form sx={formStyles}>
          <label htmlFor="name">Name</label>
          <Field name="name" type="text" />
          <div className="fieldError">
            <ErrorMessage name="name" />
          </div>

          <label htmlFor="email">Email Address</label>
          <Field name="email" type="email" />
          <div className="fieldError">
            <ErrorMessage name="email" />
          </div>

          <label htmlFor="message">Message</label>
          <Field name="message" type="text" as="textarea" />
          <div className="fieldError">
            <ErrorMessage name="message" />
          </div>

          <Button>Submit</Button>
        </Form>
      </Formik>
      <div className="ErrorMessages">
        {formSuccess && (
          <Message variant="success">
            Form submitted successfully. I will try and respond in the next 24
            hours.
          </Message>
        )}

        {formError && (
          <Message variant="error">
            Form failed. There appears to be an error. Please try again later or
            reach out on social media.
          </Message>
        )}
      </div>
    </>
  );
};

export default SignupForm;

const formStyles = {
  "label, input": {
    display: "block",
  },
  "input, textarea": {
    borderRadius: "2px",
    fontSize: 3,
    border: "1px solid #ccc",
    padding: "6px 10px",
    height: "38px",
    width: "85%",
    fontFamily: "body",
    lineHeight: 1.3,
    color: "muted",
  },
  textarea: {
    width: "100%",
    height: "120px",
  },
  label: {
    fontSize: 3,
    color: "black",
    lineHeight: 1.3,
    mb: 2,
    mt: 3,
  },
  ".fieldError": {
    color: "red",
  },
  button: {
    mt: 3,
  },
};
