// LinkResolver.js file

exports.linkResolver = (doc) => {
  if (doc.type === "page") {
    return `/${doc.uid}`;
  }
  if (doc.type === "blog_posts") {
    return `/blog/${doc.uid}`;
  }
  //static pages
  else if (doc.type === "about_page") {
    return `/about`;
  } else if (doc.type === "blog_page") {
    return `/blog`;
  } else if (doc.type === "contact_page") {
    return `/contact`;
  } else if (doc.type === "corporate_page") {
    return `/corporate`;
  } else if (doc.type === "faqs_page") {
    return `/faqs`;
  } else if (doc.type === "portfolio_page") {
    return `/portfolio`;
  } else if (doc.type === "dancers") {
    return `/dancers`;
  }

  return "/";
};
