/** @jsxImportSource theme-ui */
import * as React from "react";
import { graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";

export const FaqRow = ({ slice }) => {
  return (
    <section
      className="content-section textRow"
      sx={{
        fontSize: 3,
        mb: 5,
        h2: {
          fontSize: 5,
        },
      }}
    >
      <h2>{slice.primary.text1.text}</h2>
      {slice.items.map((item, index) => {
        return (
          <div key={`faqGroup${index}`} sx={{ mb: 5 }}>
            <h3>{item.question.text}</h3>
            <PrismicRichText field={item.answer.richText} />
          </div>
        );
      })}
    </section>
  );
};

export const query = graphql`
  fragment AHLFaqrow on PrismicActorsHeadshotsLondonDataBodyFaqrow {
    id
    slice_type
    items {
      answer {
        richText
        text
      }
      question {
        richText
        text
      }
    }
    primary {
      text1 {
        richText
        text
      }
    }
  }

  fragment HomeFaqrow on PrismicHomePageDataBodyFaqrow {
    id
    slice_type
    items {
      answer {
        richText
        text
      }
      question {
        richText
        text
      }
    }
    primary {
      text1 {
        richText
        text
      }
    }
  }
`;
