/** @jsxImportSource theme-ui */
import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import {
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaGlobe,
} from "react-icons/fa";
import Socials from "./SocialMediaIcons";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query footerQuery {
      prismicSitewide {
        data {
          social_media {
            link {
              url
              slug
              link_type
              id
            }
            logo
            text {
              raw
              html
              richText
              text
            }
          }
        }
      }
    }
  `);
  //console.log("footerdata", data);

  return (
    <footer
      sx={{
        py: 2,
        color: "muted",
        "& .visuallyHidden": {
          //josh comeau
          display: "inline-block",
          position: "absolute",
          overflow: "hidden",
          clip: "rect(0 0 0 0)",
          height: 1,
          width: 1,
          margin: -1,
          padding: 0,
          border: 0,
        },
      }}
    >
      <div
        sx={{ display: "flex", justifyContent: "space-between", fontSize: 4 }}
      >
        <div>© Ben Wulf {new Date().getFullYear()}</div>
        <Socials />
      </div>
      <div
        sx={{
          display: "flex",
          justifyContent: ["center", "flex-end"],
          pt: [4, 4, 3],
        }}
      >
        <small>
          Website by <a href="https://exposuredesign.co.uk">Exposure Design</a>
        </small>
      </div>
    </footer>
  );
};

export default Footer;
